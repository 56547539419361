<template>
  <PageLoader v-if="pageLoading" :loading="pageLoading" />

  <div v-else class="container_margin">
    <template v-if="similarCasesList.length !== 0">
      <h5 class="mb-3"><b>{{ "Top Similar Cases" }}</b></h5>
      <div class="card border shadow-sm p-2 rounded table-card">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" width="4%">{{ "ID" }}</th>
              <th scope="col" width="40%">{{ "Precedent" }}</th>
              <th scope="col" width="20%">{{ "Court" }}</th>
              <th scope="col" width="13%">{{ "Judgement Date" }}</th>
              <th scope="col" width="13%">{{ "Country" }}</th>
              <th scope="col" width="10%">{{ "Action" }}</th>
            </tr>
          </thead>

          <tbody>
            <PageLoader v-if="pageLoading" :loading="pageLoading" />

            <div
              v-else-if="similarCasesList.length === 0"
              class="container_margin"
            >
              <div class="mb-2 m-auto" style="width: 200px">
                <img src="@/assets/noData.png" alt="No Data Image" />
              </div>
              <p class="text-center text-blue font-weight-bold">
                {{ "No Precedents Data" }}
              </p>
            </div>

            <tr
              v-else
              v-for="(item, index) in paginatedPrecedents1"
              :key="index"
            >
              <td width="4%">
                <p>{{ getContinuousId1(index) }}</p>
              </td>
              <td width="40%">
                <p>{{ item.Precedent || item.Precedents }}</p>
              </td>
              <td width="20%">
                <p>{{ item.Court }}</p>
              </td>
              <td width="13%">
                <p class="text-muted font-weight-bold">
                  {{
                    item.JudgementDate !== "None"
                      ? $options.filters.formatDateLoc(item.JudgementDate)
                      : "None"
                  }}
                </p>
              </td>
              <td width="13%">
                <p>{{ item.Country }}</p>
              </td>
              <td width="10%">
                <button
                  v-if="
                    item.content_type === 'None' ||
                    item.content_type === 'NONE' ||
                    item.content_type === null
                  "
                  class="btn btn-primary disabled"
                >
                  {{ "None" }}
                </button>
                <button
                  v-else
                  class="btn btn-primary"
                  @click="openSimilarCase(item)"
                >
                  {{ "Open" }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <!-- start of Temporal Distribution chart -->
    <div
      v-if="analysis_dateRangeList.length !== 0"
      class="my-4 p-4 card border shadow-sm"
    >
      <h5 class="font-weight-bold mb-1">
        {{ "Temporal Distribution of Cases: Count over Time" }}
      </h5>
      <small class="font-12 mb-3">
        The graph visually represents the distribution of cases over a specific period
      </small>

      <apexchart
        v-if="analysis_dateRangeList.length > 0"
        :options="dateChartOptions"
        :series="dateChartSeries"
        height="450"
      ></apexchart>

      <!-- Display a message if no data is available -->
      <template v-else>
        <div class="mt-4 mb-2 m-auto" style="width: 200px">
          <img src="@/assets/noData.png" alt="No Data Image" />
        </div>
        <p class="text-center text-blue">
          <b>{{ "No Case" }}</b>
        </p>
      </template>
    </div>
    <!-- end of Temporal Distribution chart -->

    <!-- start of Top 10 Precedents chart -->
    <div
      v-if="analysis_caseList.length !== 0"
      class="p-4 card border shadow-sm"
    >
      <h5 class="mb-1">
        <b>{{ "Precedents and Citations: Top 10 Cases Count" }}</b>
      </h5>
      <small class="font-12 mb-3">
        Each bar on the chart represents a specific case, and its height
        corresponds to the number of times that particular case has been
        mentioned in precedent and citations
      </small>

      <apexchart
        v-if="analysis_caseList.length > 0"
        type="bar"
        :options="chartOptions"
        :series="chartSeries"
        height="450"
      ></apexchart>

      <!-- Display a message if no data is available -->
      <template v-else>
        <div class="mt-4 mb-2 m-auto" style="width: 200px">
          <img src="@/assets/noData.png" alt="No Data Image" />
        </div>
        <p class="text-center text-blue">
          <b>{{ "No Case" }}</b>
        </p>
      </template>
    </div>
    <!-- end of Top 10 Precedents chart -->

    <!-- start of Top 10 Statutes chart -->
    <div
      v-if="analysis_statueList.length !== 0"
      class="my-4 p-4 card border shadow-sm"
    >
      <h5 class="mb-1">
        <b>{{ "Top 10 Statutes Count" }}</b>
      </h5>
      <small class="font-12 mb-3">
        The chart provides a visual representation of the statutes
        referenced in a collection of cases, along with the frequency of
        their mentions
      </small>

      <apexchart
        v-if="analysis_statueList.length > 0"
        type="bar"
        :options="statueChartOptions"
        :series="statueChartSeries"
        height="450"
      ></apexchart>

      <!-- Display a message if no data is available -->
      <template v-else>
        <div class="mt-4 mb-2 m-auto" style="width: 200px">
          <img src="@/assets/noData.png" alt="No Data Image" />
        </div>
        <p class="text-center text-blue">
          <b>{{ "No Case" }}</b>
        </p>
      </template>
    </div>
    <!-- end of Top 10 Statutes chart -->

    <!-- start of Geographical Distribution chart -->
    <div
      v-if="analysis_countryList.length !== 0"
      class="mb-4 p-4 card border shadow-sm"
    >
      <h5 class="mb-1">
        <b>{{ "Geographical Distribution of Cases" }}</b>
      </h5>
      <small class="font-12 mb-3">
        The donut chart shows the distribution of cases based on the
        countries in which they have occurred
      </small>

      <apexchart
        v-if="analysis_countryList.length > 0"
        type="donut"
        :options="countryChartOptions"
        :series="countryChartSeries"
        height="450"
      ></apexchart>

      <template v-else>
        <div class="mt-4 mb-2 m-auto" style="width: 200px">
          <img src="@/assets/noData.png" alt="No Data Image" />
        </div>
        <p class="text-center text-blue">
          <b>{{ "No Case" }}</b>
        </p>
      </template>
    </div>
    <!-- end of Geographical Distribution chart -->

    <!-- start of All Precedents table -->
    <div class="row mb-3">
      <div class="col d-flex align-items-center">
        <h5 class="mb-0"><b>{{ "All Precedents" }}</b></h5>
      </div>

      <div v-if="all_precedents.length > 5" class="col">
        <b-pagination
          class="mb-0 float-right"
          v-model="currentPage2"
          :total-rows="all_precedents.length"
          :per-page="perPage"
          aria-controls="my-table2"
          last-number
        ></b-pagination>
      </div>
    </div>

    <table class="table card border shadow-sm p-2 rounded table-card">
      <thead>
        <tr>
          <th scope="col" width="4%">{{ "ID" }}</th>
          <th scope="col" width="40%">{{ "Precedent" }}</th>
          <th scope="col" width="20%">{{ "Court" }}</th>
          <th scope="col" width="13%">{{ "Judgement Date" }}</th>
          <th scope="col" width="13%">{{ "Country" }}</th>
          <th scope="col" width="10%">{{ "Action" }}</th>
        </tr>
      </thead>

      <tbody>
        <PageLoader v-if="pageLoading" :loading="pageLoading"></PageLoader>

        <template v-else-if="all_precedents.length === 0">
          <div class="mt-4 mb-2 m-auto" style="width: 200px">
            <img src="@/assets/noData.png" alt="No Data Image" />
          </div>
          <p class="text-center text-blue font-weight-bold">
            {{ "No Precedents Data" }}
          </p>
        </template>

        <tr
          v-else
          :key="index"
          v-for="(item, index) in paginatedPrecedents2"
        >
          <td width="4%">
            <p>{{ getContinuousId2(index) }}</p>
          </td>
          <td width="40%">
            <p>{{ item.title }}</p>
          </td>
          <td width="20%">
            <p>{{ item.court }}</p>
          </td>
          <td width="13%">
            <p class="text-muted font-weight-bold">
              {{
                item.judgement_date !== "None"
                  ? $options.filters.formatDateLoc(item.judgement_date)
                  : "None"
              }}
            </p>
          </td>
          <td width="13%">
            <p>{{ item.location }}</p>
          </td>
          <td width="10%">
            <button
              v-if="
                item.full_content.type === 'None' ||
                item.full_content.type === 'NONE' ||
                item.full_content.type === null
              "
              class="btn btn-primary disabled"
            >
              {{ "None" }}
            </button>
            <button
              v-else
              class="btn btn-primary"
              @click="openCase(item.id)"
            >
              {{ "Open" }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end of All Precedents table -->
  </div>
</template>

<script>
/* eslint-disable */
// import Auth from "@/store/Auth.js";
import PageLoader from "../../components/PageLoader.vue";
import CaseSearch from "@/store/CaseSearch.js";
export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      rows: 100,
      perPage: 5,
      currentPage1: 1,
      currentPage2: 1,
      page: 0,
      permissions: localStorage.permissions,
      pageLoading: true,
      jurisID: 0,
      similarCasesList: [],
      analysis_caseList: [],
      analysis_countryList: [],
      analysis_courtsList: [],
      analysis_dateRangeList: [],
      analysis_statueList: [],
      all_precedents: [],
      chartOptions: {
        chart: { type: "bar", height: 450, width: 700 },
        xaxis: {
          categories: [],
          labels: {
            show: false,
            // rotate: -75,
            // style: {
            //   fontSize: "11px",
            // },
          },
          // overwriteCategories: [],
        },
        yaxis: {
          tickAmount: 4,
          min: 0,
          max: 0,
        },
        colors: [
          "#d11141",
          "#00b159",
          "#00aedb",
          "#f37735",
          "#ffc425",
          "#a200ff",
          "#191d58",
          "#007b7b",
          "#a05000",
          "#8ec127",
        ],
        plotOptions: {
          bar: { horizontal: false, columnWidth: "40px", distributed: true },
        },
        dataLabels: { enabled: false },
        legend: {
          show: true,
          position: "right",
          width: 300,
        },
      },
      chartSeries: [{ name: "Count", data: [] }],

      statueChartOptions: {
        chart: { type: "bar", height: 450, width: 700 },
        xaxis: {
          categories: [],
          labels: {
            show: false,
            // rotate: -75,
            // style: {
            //   fontSize: "11px",
            // },
          },
          // overwriteCategories: [],
        },
        yaxis: {
          tickAmount: 4,
          min: 0,
          max: 0,
          stepSize: 2,
        },
        colors: [
          "#d11141",
          "#00b159",
          "#00aedb",
          "#f37735",
          "#ffc425",
          "#a200ff",
          "#191d58",
          "#007b7b",
          "#a05000",
          "#8ec127",
        ],
        plotOptions: {
          bar: { horizontal: false, columnWidth: "40px", distributed: true },
        },
        dataLabels: { enabled: false },
        legend: {
          show: true,
          position: "right",
          width: 300,
        },
      },
      statueChartSeries: [{ name: "Count", data: [] }],

      dateChartOptions: {
        chart: { type: "area", height: 450, width: 700 },
        xaxis: {
          categories: [],
          labels: {
            rotate: -75,
            style: {
              fontSize: "11px",
            },
          },
          // overwriteCategories: [],
        },
        yaxis: {
          tickAmount: 0.5,
          min: 0,
          max: 0,
          stepSize: 2,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [100],
          },
        },
        colors: ["#191d58"],
        plotOptions: {
          bar: { horizontal: false, columnWidth: "33px", distributed: true },
        },
        dataLabels: { enabled: false },
        legend: {
          show: false,
          // position: "right",
        },
        stroke: {
          curve: "smooth",
        },
      },
      dateChartSeries: [{ name: "Count", data: [] }],

      countryChartOptions: {
        chart: { type: "donut", height: 450, width: 700 },
        labels: [],
        colors: [
          "#d11141",
          "#00b159",
          "#00aedb",
          "#f37735",
          "#ffc425",
          "#a200ff",
          "#191d58",
          "#007b7b",
          "#a05000",
          "#8ec127",
        ],
        dataLabels: { enabled: true },
        legend: {
          show: true,
          // position: "right",
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              return value + " case(s)";
            },
          },
        },
      },
      countryChartSeries: [],

      // court1ChartOptions: {
      //   chart: { type: "pie", height: 450, width: 500 },
      //   labels: [],
      //   colors: [
      //     "#d11141",
      //     "#00b159",
      //     "#00aedb",
      //     "#f37735",
      //     "#ffc425",
      //     "#a200ff",
      //     "#191d58",
      //     "#007b7b",
      //     "#a05000",
      //     "#8ec127",
      //   ],
      //   dataLabels: { enabled: true },
      //   legend: {
      //     show: true,
      //     // position: "right",
      //   },
      //   tooltip: {
      //     enabled: true,
      //     y: {
      //       formatter: function (value) {
      //         return value + " case(s)";
      //       },
      //     },
      //   },
      // },
      // court1ChartSeries: [],
    };
  },

  computed: {
    paginatedPrecedents1() {
      return this.similarCasesList.slice(
        (this.currentPage1 - 1) * this.perPage,
        this.currentPage1 * this.perPage
      );
    },
    paginatedPrecedents2() {
      return this.all_precedents.slice(
        (this.currentPage2 - 1) * this.perPage,
        this.currentPage2 * this.perPage
      );
    },
  },

  methods: {
    updateCurrentPage(source) {
      this.currentPage1 = source.page1 ? parseInt(source.page1, 10) : 1;
      this.currentPage2 = source.page2 ? parseInt(source.page2, 10) : 1;
    },

    getContinuousId1(index) {
      return (this.currentPage1 - 1) * this.perPage + index + 1;
    },

    getContinuousId2(index) {
      return (this.currentPage2 - 1) * this.perPage + index + 1;
    },

    openCase(data) {
      this.$router.push({
        name: "CaseSearchPrecedentSummary",
        query: { page2: this.currentPage2 },
        params: {
          jurisId: this.jurisID,
          id: data,
        },
      });
    },

    openSimilarCase(data) {
      this.all_precedents.forEach((item) => {
        if (
          item.title === (data.Precedents ? data.Precedents : data.Precedent)
        ) {
          this.$router.push({
            name: "CaseSearchPrecedentSummary",
            query: { page1: this.currentPage1 },
            params: {
              jurisId: this.jurisID,
              id: item.id,
            },
          });
          return;
        }
      });
    },
  },

  created() {
    CaseSearch.OpenSummary(this.$route.params.id)
      .then((response) => {
        const DATA = response.data.data;

        //JurisID
        this.jurisID = DATA.id;

        // Similar Cases
        const similarCases = DATA.values.find(
          (el) => el.key === "precedents_similar"
        )?.value;
        this.similarCasesList = similarCases ? JSON.parse(similarCases) : [];

        // Precedents Analysis
        var precedentsAnalysis = DATA.values.find(
          (el) => el.key === "precedents_analysis"
        )?.value;
        precedentsAnalysis = precedentsAnalysis
          ? JSON.parse(precedentsAnalysis)["all"]
          : false;
        if (precedentsAnalysis) {
          this.analysis_caseList = Object.entries(
            precedentsAnalysis["case"]
          ).map(([caseName, count]) => ({ caseName, count }));

          this.analysis_countryList = Object.entries(
            precedentsAnalysis["country"]
          ).map(([country, count]) => ({ country, count }));

          this.analysis_courtsList = Object.entries(
            precedentsAnalysis["courts"]
          ).map(([country, courts]) => ({
            country,
            courts: Object.entries(courts).map(([court, count]) => ({
              court,
              count,
            })),
          }));

          this.analysis_dateRangeList = Object.entries(
            precedentsAnalysis["date_range"]
          ).map(([dateRange, count]) => ({ dateRange, count }));

          this.analysis_statueList = Object.entries(
            precedentsAnalysis["statue"]
          ).map(([statue, count]) => ({ statue, count }));
        }

        // Top 10 Cases
        var first10Items =
          this.analysis_caseList.length > 10
            ? this.analysis_caseList.splice(0, 10)
            : this.analysis_caseList;

        this.chartOptions.xaxis.categories = first10Items.map(
          (item) => item.caseName
        );

        this.chartOptions.xaxis.overwriteCategories = first10Items.map((item) =>
          item.caseName.slice(0, 16)
        );

        this.chartSeries[0].data = first10Items.map((item) => item.count);

        this.chartOptions.yaxis.max = Math.max(...this.chartSeries[0].data);

        // Top 10 Statutes
        this.statueChartOptions.xaxis.categories = this.analysis_statueList.map(
          (item) => item.statue
        );

        this.statueChartSeries[0].data = this.analysis_statueList.map(
          (item) => item.count
        );

        this.statueChartOptions.yaxis.max = Math.max(
          ...this.statueChartSeries[0].data
        );

        // Temporal Dist
        this.dateChartOptions.xaxis.categories =
          this.analysis_dateRangeList.map((item) => item.dateRange);

        this.dateChartSeries[0].data = this.analysis_dateRangeList.map(
          (item) => item.count
        );

        let prevLabel = null;
        let year = null;
        const labels = this.dateChartOptions.xaxis.categories;
        const series = this.dateChartSeries[0].data;
        for (let i = 0; i < labels.length; ) {
          year = labels[i].split("-")[0];
          if (prevLabel === year) {
            series[i - 1] += series[i];
            series.splice(i, 1);
            labels.splice(i, 1);
          } else {
            labels[i] = year;
            prevLabel = year;
            i++;
          }
        }

        this.dateChartOptions.yaxis.max = Math.max(
          ...this.dateChartSeries[0].data
        );

        // Geogrephical Dist
        this.countryChartOptions.labels = this.analysis_countryList.map(
          (item) => item.country
        );

        this.countryChartSeries = this.analysis_countryList.map(
          (item) => item.count
        );

        // All precedents
        this.all_precedents.push(...DATA.precedents);
        this.pageLoading = false;
      })
      .catch((error) => {
        console.log(error);
      });

    this.updateCurrentPage(this.$route.query);
  },

  watch: {
    currentPage1(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.$router.push({
          path: this.$route.path,
          query: { ...this.$route.query, page1: newPage },
        });
      }
    },

    currentPage2(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.$router.push({
          path: this.$route.path,
          query: { ...this.$route.query, page2: newPage },
        });
      }
    },

    $route(to, from) {
      this.updateCurrentPage(to.query);
    },
  },
};
</script>

<style scoped>
/* table */
.table {
  border: none;
  background: white;
  table-layout: fixed;
}

tbody {
  display: block;
  max-height: auto;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}

.container_margin {
  max-width: 100%;
  padding: 2rem;
}
</style>
